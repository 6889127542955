import './EventCalendar.css'
import { useCollection } from '../../hooks/useCollection'

import "firebase/firestore";
import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar';


export default function EventCalendar() {


  const { documents, error } = useCollection(
    'events',null,
    ["date", "desc"]
    )

   

  return (
    <div className="eventCalendarWrapper">
        <Navbar />
        {/* Add eventCalendar specific Navbar */}
        <div className="eventCalendarHeader">
            <h1>Neuromodec Webinars</h1>
        </div>

        <div className="eventCalendarBody">

        <ul>
        {error && <p className="error">{error}</p>}
          {documents && documents.map(doc => (
            <li key={doc.id}>
              
                 <Link to={`/events/${doc.id}`}> 
                <div className="eventCard-wrapper">
                    <div className="eventCard-img">
                    <img src={doc.img_url} alt="" />
                    </div>
                    <div className="eventCard-details">
                        <h3>{doc.name}</h3>

                        <p>{doc.day}, {doc.month} {doc.day_digit}, {doc.year} | {doc.start_time} -  {doc.end_time} (ET)</p>
                        <p>{doc.type}</p> 
                      {doc.past_event && <span className="completed-badge">Completed</span>}
                      {doc.canceled && !doc.past_event && <span className="completed-badge">Canceled</span>}
                      {!doc.past_event && !doc.canceled && <span className="upcoming-badge">Upcoming</span>}                       
                    </div>
                </div>
                </Link>
            </li>
          ))}
        </ul>
        </div>

    </div>
  )
}
