
import { useParams } from 'react-router-dom'
import { useDocument } from '../hooks/useDocument'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Navbar from './Navbar'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import './PostSummary.css'
import PostComments from './PostComments'

export default function PostSummary() {

    const { id } = useParams()
    const {error, document} = useDocument('posts', id)

    if (error) {
        return <div className="error">{error}</div>
    }

    if (!document) {
        return <div className="loading"><CircularProgress /></div>
    }

  return (
    <div className="feed">

        <Navbar />


      <div key={document.id}>
              <div className="user-post-wrap">
                <div className="user-post-header">
                  <img src={document.userPhotoUrl} alt="User Profile Picture" className="avator" />
                  <p>{document.userFirstName} {document.userLastName}<span>{formatDistanceToNow(document.createdAt.toDate(), {addSuffix: true})}</span></p>
                </div>
                <div className="user-post-details">
                  <p>{document.post_content}</p>
                </div>
                <div className="user-post-img">
                </div>
                <div className="user-post-engagement">
                  <div className="post-likes">
                     <button><FavoriteBorderIcon /></button>
                     <p>{document.likes.length}</p>
                  </div>
                  <div className="post-comments">
                     <button><ChatBubbleOutlineOutlinedIcon /></button>
                    <p>{document.comments.length}</p>
                  </div>
                </div>
              </div>
                <ul>
                {document.comments.length > 0 && document.comments.map(comment => (
                    <li key={comment.id}>
                    <div className="comments-by-users">
                  <div className="comment-by-user-wrap">
                  <div className="comment-user-post-header">
                      <img src={comment.photoURL} alt="" className="avatar"/>
                      <p><span>{comment.first_name} {comment.last_name}</span><span>{formatDistanceToNow(comment.createdAt.toDate(), {addSuffix: true})}</span></p>
                      </div>
                        <div className="user-comment-details">
                        <p>{comment.content}</p>
                        </div>
                    </div>
                    </div>
                    </li> 
                ))}
            </ul>
        </div>
     
     <div> <PostComments post={document}/></div>
       
      

    

    </div>
  )
}
