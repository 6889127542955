import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';


// styles & images
import './Navbar.css'
import NeuromodecLogo from '../assets/logo.svg'

export default function Navbar() {
  const { logout, isPending } = useLogout()
  const { user } = useAuthContext()

  return (
    <nav className="navbar">
      <ul>
    
        {!user && (
          <>
           <li className="logo">
              <img src={NeuromodecLogo} alt="Neuromodec logo" />
            </li>
            <li><Link to="/events">Upcoming Events</Link></li>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/signup">Signup</Link></li>
          </>
        )}

        {user && (
          <>
          
          <li className="goBackBtn"><Link to="/"><ArrowBackOutlinedIcon fontSize="medium"/></Link></li>
         
          {/* <li>
            {!isPending && <button className="btn" onClick={logout}>Logout</button>}
            {isPending && <button className="btn" disabled>Logging out...</button>}
          </li> */}
          </>
        )}
      </ul>
    </nav>
  )
}