import './Event.css'
import { useEffect, useState } from 'react'
import Avatar from "../../components/Avatar"
import { useParams } from 'react-router-dom'
import { useDocument } from '../../hooks/useDocument'
import { useAuthContext } from '../../hooks/useAuthContext'
import { timestamp, timestampDate} from '../../firebase/config'
import firebase from "firebase/app";
import Navbar from '../../components/Navbar'
import EventAttendees from '../../components/EventAttendees'
import EventRegister from '../../components/EventRegister'
import EventOrganizer from '../../components/EventOrganizer'
import { useFirestore } from '../../hooks/useFirestore'
import "firebase/firestore"
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import Linkify from 'react-linkify';
import Alert from '@mui/material/Alert';
import { HashLink } from 'react-router-hash-link';



export default function Event() {

  const { id } = useParams()
  const {error, document} = useDocument('events', id)
  const { updateDocument, response } = useFirestore('events')
  const { user } = useAuthContext()
  const [postText, setPostText] = useState('')
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLasttName] = useState('')
  const [userPhotoUrl, setUserPhotoUrl] = useState('')

    if (error) {
        return <div className="error">{error}</div>
    }

    if (!document) {
        return <div className="loading"></div>
    }

    const db = firebase.firestore();

   if (user) {

    var docRef = db.collection("users").doc(user.uid);
   
    docRef.get().then((doc) => {
    
    if (doc.exists) {
        var uuser = doc.data();
        setUserFirstName(uuser.first_name)
        setUserLasttName(uuser.last_name)
        setUserPhotoUrl(uuser.photoURL)
    } else {
        console.log("No such document!");
    }
}).catch((error) => {
    console.log("Error getting document:", error);
});
   } else {}

    const handleSubmit = async (e) => {
      e.preventDefault()

const randomId = user.displayName
const idLeft = randomId.slice(2,4)
const idRight = randomId.slice(3,5)

const createRandomId = `${idLeft}-${Math.random()}-${idRight}`

    e.preventDefault()

    const discussionToAdd = {
        photoURL: user.photoURL,
        content: postText,
        createdAt: timestamp.fromDate(new Date()),
        id: createRandomId,
        comment_by:user.uid,
        first_name:userFirstName,
        last_name:userLastName
    }

    await updateDocument(id, {   
        discussion: [...document.discussion, discussionToAdd]
        
    })
   if (!response.error) {
    setPostText('')
    
   }

   
      
    //   db.collection("discussions").doc(id).set({
    //     post_content: postText,
    //     createdAt:discussionTime,
    //     comments: [],
    //     likes:[],
    //     userFirstName,
    //     userLastName,
    //     userPhotoUrl,
    //     posted_by: user.uid,
    //     posted_on:timestampDate,
    // })
    //   .then(() => {
    //     console.log("Document successfully written!");
    //     setPostText('')
    // })

    // .catch((error) => {
    //     console.error("Error writing document: ", error);
    // });
    
    }

  return (
  
        <div key={document.id} className="eventContainer">
           <Navbar />
           
        <div className="eventHeader">
          <div className="event-image">
               {/* {document.zoom_url && <span className="live-badge">LIVE</span>} */}
               {/* { document.starting_soon && <span className="soon-badge">STARTING SOON</span>} */}
            <img src={document.img_url} alt="" />
            {/* {document.starting_soon && <Alert severity="info">This webinar is taking place today (Thursday, April 18 at 10 AM (ET)). Please note that the Zoom link to join the Webinar will be posted on this page 1 hour before the webinar start time.</Alert>} */}
            {/* {document.starting_soon && <Alert severity="info">This webinar is taking place today (Thursday, April 18 at 10 AM (ET)). The discussion Board is now open. Feel free to start asking questions about the webinar <a href="#qa">here</a>. Please note that the Zoom link to join the Webinar will be posted on this page 1 hour before the webinar start time.</Alert>} */}
             {document.canceled && <Alert severity="info">This webinar is canceled. Our team is actively working on rescheduling the webinar. We will keep you informed of any developments in this regard. We apologize for any inconvenience this may have caused.</Alert>}
               {/* {user && document.zoom_url && <Alert severity="success">This webinar is starting soon. You can join the discussion and ask questions <a href="#qa">here</a>. If you haven't registered, you can still attend! <a target="a_blank" href={document.zoom_url}>Join the Webinar via Zoom</a></Alert>}  */}
             {/* {user && document.zoom_url && <Alert severity="success">This webinar is currently running. You can join the discussion and ask questions <a href="#qa">here</a>. If you haven't registered, you can still attend! <a target="a_blank" href={document.zoom_url}>Join the Webinar via Zoom</a></Alert>} */}
          </div>
          <div className="event-wrapper">
          <div className="event-left">
          <h1>{document.name}</h1>
          <p className="event-d-t">{document.day}, {document.month} {document.day_digit}, {document.year} | {document.start_time} -  {document.end_time} (ET)</p>
          <p>{document.summary}</p>
           <div className="qa-cta-top">
          {document.zoom_url && <button className="btn-qa"><HashLink smooth to="#qa">Join the discussion / Q&A</HashLink></button>}
          </div>
          <EventOrganizer event={document} />
            <div className="event-overview">
            <h3>Overview</h3>
            <p>{document.overview}</p>
            <h3>Speakers</h3>
            <ul>
            {document.speakers.length > 0 && document.speakers.map(speaker => (
            <li key={speaker.id}>
              <p>- {speaker.first_name} {speaker.last_name}</p>
            </li>
            ))}
            </ul>
           
            </div>
            {!user && document.zoom_url &&
            <>
            <div className="qanda">
            <h3>Discussion</h3>
            <Alert severity="info">Sign in to view the discussion board and join the discussion.</Alert>
            </div>
            </>
            }

            {user && document.zoom_url &&
            <>
            <div className="qanda">
            <h3>Discussion</h3>

            <Alert severity="warning">All Neuromodec Connect members and webinar attendees are reminded about the requirement of adhering to the Neuromodec Connect Code of Conduct, Social Media Policy, and policies regarding avoiding harassment and discrimination. <a target="a_blank" href="https://neuromodec.org/webinar-series-2024/code-of-conduct.html">Read more here.</a> </Alert>
      <form onSubmit={handleSubmit}>
    
      <div className="post-wrapper">
      <div className="post-input-box">
        <div className="post-img-wrap">
        <Avatar src={user.photoURL} className="avator"/>
        </div>
        <div className="post-text">
        <textarea
          required 
          type="email" 
          placeholder='Ask a question or reply to one..'
          onChange = {(e) => setPostText(e.target.value)}
          value={postText}
        />
        </div>
        <div id="qa" className="post-cta">
          <button className="btn">Post</button>
        </div>
        </div>
      </div>
      </form>

        <ul>
          {document.discussion.length > 0 && document.discussion.map(disc => (
            <li key={disc.id}>
            <div className="user-post-wrap">
                <div className="user-post-header">
                  <img src={disc.photoURL} alt="User Profile Picture" className="avator" />
                  <p>{disc.first_name} {disc.last_name}<span>{formatDistanceToNow(disc.createdAt.toDate(), {addSuffix: true})}</span></p>
                </div>
                <div className="user-post-details">
                  <p><Linkify>{disc.content}</Linkify></p>
                </div>
              </div>
              </li>
            ))}
               </ul>

            </div>
            </>
            }
             <div className="event-attendees">
              <EventAttendees event={document}/>
            </div>
            </div>

            <div className="event-right">
            <EventRegister event={document}/>
            </div>
            </div> 
            </div>
            </div>  
  
          )
    }
