import './PostList.css'

import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import PostComments from './PostComments';
import { useDocument } from '../hooks/useDocument'
import { Link } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import { useState } from "react"
import { timestamp } from '../firebase/config'
import { useAuthContext } from '../hooks/useAuthContext'
import { useFirestore } from '../hooks/useFirestore'
import PostLikes from './PostLikes';
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import Linkify from 'react-linkify';


export default function PostList( { posts } ) {


  return (
    <div className="postList">
     
        {posts.length === 0 && <Alert severity="info">No posts to show</Alert>}
        {posts.map(post => (
          <div key={post.id}>
              <div className="user-post-wrap">
                <div className="user-post-header">
                  <img src={post.userPhotoUrl} alt="User Profile Picture" className="avator" />
                  <p>{post.userFirstName} {post.userLastName}<span>{formatDistanceToNow(post.createdAt.toDate(), {addSuffix: true})}</span></p>
                </div>
                <div className="user-post-details">
                  <p><Linkify>{post.post_content}</Linkify></p>
                </div>
                <div className="user-post-img">
                </div>
                <div className="user-post-engagement">
                 <PostLikes postLike={post} />
                  <div className="post-comments">
                    <Link to={`/posts/${post.id}`}>
                     <button><ChatBubbleOutlineOutlinedIcon /></button>
                     </Link>
                    <p>{post.comments.length}</p>
                  </div>
                </div>
              </div>
                    <ul>
                {post.comments.length > 0 && post.comments.map(comment => (
                    <li key={comment.id}>
                    <div className="comments-by-users">
                  <div className="comment-by-user-wrap">
                  <div className="comment-user-post-header">
                      <div className="comment-img">
                      <img src={comment.photoURL} alt=""/>
                      </div>
                      <p><span>{comment.first_name} {comment.last_name}</span><span>{formatDistanceToNow(comment.createdAt.toDate(), {addSuffix: true})}</span></p>
                      </div>
                        <div className="user-comment-details">
                        <p>{comment.content}</p>
                        </div>
                    </div>
                    </div>
                    </li>
                ))}
            </ul>
                <div className="post-gap"></div>
        </div>
        
        ))}
    
    </div>
    
  )
}
