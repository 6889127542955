import { useEffect, useState } from 'react'

import './Feed.css'
import { timestamp, timestampDate} from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
import Avatar from "../../components/Avatar"
import { useCollection } from '../../hooks/useCollection'
import { useFirestore } from '../../hooks/useFirestore'
import { useHistory } from 'react-router'
import firebase from "firebase/app";
import "firebase/firestore";
import PostList from '../../components/PostList'
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import { HashLink } from 'react-router-hash-link';


export default function Feed() {

  //Post field values 

  const [postText, setPostText] = useState('')
  const [postImage, setPostImage] = useState(null)
  const [postDate, setPostDate] = useState('')
  const [postImageError, setPostImageError] = useState(null)
  const [users, setUsers] = useState([])
  const { user } = useAuthContext()
  const { addDocument, response } = useFirestore('posts')
  const history = useHistory()
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLasttName] = useState('')
  const [userPhotoUrl, setUserPhotoUrl] = useState('')
  const [likes, setLikes] = useState([])
  const { documents, error } = useCollection(
  'posts',null,
  ["createdAt", "desc"]
  )

  const db = firebase.firestore();

   var docRef = db.collection("users").doc(user.uid);

   docRef.get().then((doc) => {
    if (doc.exists) {
        var uuser = doc.data();
        setUserFirstName(uuser.first_name)
        setUserLasttName(uuser.last_name)
        setUserPhotoUrl(uuser.photoURL)
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}).catch((error) => {
    console.log("Error getting document:", error);
});



// class User {
//   constructor (firstName, lastName, handle ) {
//   this.firstName = firstName;
//   this.lastName = lastName;
//   this.handle = handle;
// }
// toString() {
//   return this.firstName + ', ' + this.lastName + ', ' + this.handle;
// }
// }
 
// var userConverter = {
//   toFirestore: function(user) {
//     return {
//       first_name: user.firstName,
//       last_name: user.lastName,
//       handle: user.handle
//     };
//   },
//   fromFirestore: function(snapshot, options) {
//     const data = snapshot.data(options);
//     return new User(data.first_name, data.lastName, data.handle);
//   }
  
// }

// db.collection("users").doc(user.uid)
//   .withConverter(userConverter)
//   .get().then((doc) => {
  
//     if (doc.exists){
//       // Convert to City object
//       var uuser = doc.data();
//       userFirstName = uuser.firstName
//     } else {
//       console.log("No such document!");
//     }console.log(userFirstName)}
//     ).catch((error) => {
//       console.log("Error getting document:", error);
//     });

  // useEffect(() => {

  //   if (documents) {
  //     const options = documents.map(user => {
  //       return { value: user.id, firstName: user.first_name}
  //     })
  //     setUsers(options)
  //     console.log(options)
     
  //   }

  // },[documents])

  // const usersList = users.map((u) => {
  //   return {
  //     handle: u.value.handle,
  //     firstName: u.value.first_name
  //   }
  // })

  // console.log(usersList)


  const handleSubmit = async (e) => {
    e.preventDefault()

  
  const post = {
      post_content: postText,
      post_image_url: postImage,
      comments: [],
      likes:[],
      userFirstName,
      userLastName,
      userPhotoUrl,
      posted_by: user.uid,
      posted_on:timestampDate,
  }

    await addDocument(post)

  //   db.collection("posts-by-user").doc(user.uid).collection("post").doc().set({
  //     post_content: postText,
  //     post_image_url: postImage,
  //     comments: [],
  //     likes:[],
  //     userFirstName,
  //     userLastName,
  //     userPhotoUrl,
  //     posted_on:timestampDate,
     
  //   })
  //   .then(() => {
  //     console.log("Document successfully written!");
  // })
  // .catch((error) => {
  //     console.error("Error writing document: ", error);
  // });
  
    
    if (!response.error) {
      setPostText('')
    }
  }
  
  const handleFileChange = (e) => {
    setPostImage(null)
    let selected = e.target.files[0]
    console.log(selected)

    if (!selected) {
     //set string to "no image" before pushing to avoid errors
      return
    }
    if (!selected.type.includes('image')) {
      setPostImageError('Selected file must be an image')
      return
    }
    if (selected.size > 500000) {
      setPostImageError('Image file size must be less than 100kb')
      return
    }
    
    setPostImageError(null)
    setPostImage(selected)
    console.log('thumbnail updated')
  }
   
  return (

   
    <div className="feed"> 

<Alert severity="info">Neuromodec Connect is currently in Beta. Please let us know if you encounter any issues by contacting: contact@neuromodec.com</Alert>
   
      <div className="feedHeader">
      <h2>Webinar News & General Discussions</h2>
      </div>
      <form onSubmit={handleSubmit}>
      <div className="post-wrapper">
      <div className="post-input-box">
        <div className="post-img-wrap">
        <Avatar src={user.photoURL} className="avator"/>
        </div>

        <div className="post-text">
        <textarea
          required 
          type="email" 
          placeholder='Say something here... You can share information about upcoming events, a job posting, a new publication, news, etc..'
          onChange = {(e) => setPostText(e.target.value)}
          value={postText}
        />
        </div>
        </div>
        <div className="post-footer">
          <ul className="post-actions">
            {/* <li>
              <label htmlFor="file">
              <span><AddPhotoAlternateOutlinedIcon fontSize="medium"/></span></label>
          <input
          type="file" id="file"
          onChange={handleFileChange}
        />
      
        <div>
          
        </div>
        {postImageError && <div className="error">{postImageError}</div>}</li> */}
           
          </ul>
        <div className="post-cta">
          <button className="btn">Post</button>
        </div>
      </div>
      </div>
      </form>

      <div className="flev-wrapper">
        {/* <div className="flev-thumbnail">
        </div> */}
        <div className="flev-details">
        <span className="soon-badge">Completed</span>
        <h3>Neuromodulation as a Fatigue and Workload Countermeasure: Perceptions, Performance, and Ethics Dilemma</h3>
        <p>In this webinar, we will present the utility and effectiveness of transcranial direct current stimulation as a fatigue countermeasure and discuss its applications from equity and ethics perspectives.</p>
        <button className="btn" ><HashLink smooth to="/events/JBfEqazKcOgnGow1jiKZ#qa">See the Discussion / Q&A</HashLink></button>
        </div>
      </div>


{error && <p className="error">{error}</p>}
    {documents && <PostList posts = {documents} />}
     
    <Link to="/events"><button className="btn-mobile">Browse Webinars</button> </Link>

    </div>
      
     
  )
}
