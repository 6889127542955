import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import NeuromodecLogo from '../../assets/logo.svg'

// styles
import './Login.css'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  return (

    <div className="login-bg-container">
      
    <form onSubmit={handleSubmit} className="auth-form">
    <div className="logo">
      <img src={NeuromodecLogo} alt="Neuromodec logo" />
      </div>
      <h2>login</h2>
      <label>
        <span>Email</span>
        <input
          required
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email} 
        />
      </label>
      <label>
        <span>Password:</span>
        <input 
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password} 
        />
      </label>
      {!isPending && <button className="btn">Log in</button>}
      {isPending && <button className="btn" disabled>loading</button>}
      {error && <div className="error">{error}</div>}
      <p>Don't have an account? you can <Link to="/signup">create one</Link> for free.</p>
      <span>If you're having trouble accessing your account, please e-mail: contact@neuromodec.com</span>
    </form>
    </div>
  )
}