
import './PostComments.css'
import { useState } from "react"
import { timestamp } from '../firebase/config'
import { useAuthContext } from '../hooks/useAuthContext'
import { useCollection } from '../hooks/useCollection'
import { useFirestore } from '../hooks/useFirestore'
import firebase from "firebase/app";
import "firebase/firestore";


export default function PostComments( { post } ) {

const [newComment, setNewComment] = useState('')
const { updateDocument, response } = useFirestore('posts')
const { user } = useAuthContext()
const [commentUserFirstName, setCommentUserFirstName] = useState('')
const [commentUserLastName, setCommentUserLasttName] = useState('')


const db = firebase.firestore();

var docRef = db.collection("users").doc(user.uid);



docRef.get().then((doc) => {
   
 if (doc.exists) {
     var cUser = doc.data();
     setCommentUserFirstName(cUser.first_name)
     setCommentUserLasttName(cUser.last_name)
 } else {
     // doc.data() will be undefined in this case
     console.log("No such document!");
 }
}).catch((error) => {
 console.log("Error getting document:", error);
});



const handleSubmit = async (e) => {

const randomId = user.displayName
const idLeft = randomId.slice(2,4)
const idRight = randomId.slice(3,5)

const createRandomId = `${idLeft}-${Math.random()}-${idRight}`


    e.preventDefault()

    const commentToAdd = {
        photoURL: user.photoURL,
        content: newComment,
        createdAt: timestamp.fromDate(new Date()),
        id: createRandomId,
        comment_by:user.uid,
        first_name:commentUserFirstName,
        last_name:commentUserLastName
    }

    
    await updateDocument(post.id, {   
        comments: [...post.comments, commentToAdd]
        
    })
   if (!response.error) {
    setNewComment('')
    
   }

}

  return (
    <div>
    <form className="" onSubmit={handleSubmit}>
    <div className="user-post-comments-wrap">
      <div className="post-input-box">
        <div className="post-img-wrap">
        <img src={user.photoURL} alt="" className="avatar" />
        </div>
            <div className="post-text">
                <textarea
                required 
                onChange = {(e) => setNewComment(e.target.value)}
                value = {newComment}
                type="email" 
                placeholder='Comment here...'
                />
            </div>
            <div className="post-comment-btn">
            <button>Reply</button>
            </div>
         </div>
    </div>
    </form>

    {/* <ul>
        {post.comments.length > 0 && post.comments.map(comment => (
            <li key={comment.id}>
            <div className="comments-by-users">
          <div className="comment-by-user-wrap">
          <div className="comment-user-post-header">
              <img src="https://pbs.twimg.com/profile_images/1012717264108318722/9lP-d2yM_400x400.jpg" alt="" className="avator" />
              <p><span>@Steve Schoger</span><span>. Jun 27</span></p>
              </div>
                 <div className="user-comment-details">
                 <p>If you're tired of using outline styles for secondary buttons, a soft solid background based on the text color can be a great alternative.</p>
                 </div>
            </div>
             </div>
            </li>
        ))}
    </ul> */}
    </div>
  )
}
