import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSignup } from '../../hooks/useSignup'
import NeuromodecLogo from '../../assets/logo.svg'
import Select from 'react-select'


// styles
import './Signup.css'

// const degrees = [
// { value: 'Undergrad', label: 'Undergraduate Student'},
// { value: 'BS', label: 'Bachelors Degree'},
// { value: 'MS', label: 'Masters Student'},
// { value: 'MS', label: 'Masters Degree'},
// { value: 'PhD Candidate', label: 'PhD Candidate'},
// { value: 'PhD', label: 'PhD Degree'},
// { value: 'PostDoc', label: 'PostDoc'},
// { value: 'MD', label: 'Medical Doctor'},
// { value: 'Other', label: 'Other'}
// ]



export default function Signup() {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailError, setThumbnailError] = useState(null)
  const [affiliation, setAffiliation] = useState('')
  const [degree, setDegree] = useState('')
  const { signup, isPending, error } = useSignup()
  const [thumbnailErr, setThumbnailErr] = useState(true)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    const createDisplayName = `${firstName}${lastName}${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 10)}`
   const displayName = createDisplayName
      signup(firstName, lastName, email, password, displayName, affiliation)
    
  }

  // const handleFileChange = (e) => {
  //   setThumbnail(null)
  //   let selected = e.target.files[0]
  //   console.log(selected)
  //   console.log(selected.size)
  //   const maxSize = 1024 * 1024

  //   if (!selected) {
  //     setThumbnailError('Please select a file')
  //     setThumbnailErr(true)
  //     return
  //   }
  //   if (!selected.type.includes('image')) {
  //     setThumbnailError('Selected file must be an image')
  //     setThumbnailErr(true)
  //     return
  //   }
  //   if (selected.size > maxSize) {
  //     setThumbnailError('Image file size must be less than 100kb')
  //     setThumbnailErr(true)
  //     return
  //   }
  //   else {setThumbnailErr(false)}
  //   setThumbnailError(null)
  //   setThumbnail(selected)
  //   console.log('thumbnail updated')
  // }

  return (
     
    <div className="mainWrapper">
       
      <div className="leftContainer">

      <div className="logo">
        <img src={NeuromodecLogo} alt="Neuromodec logo" />
      </div>

        
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>Webinar Series Registration</h2>
      <p>Registration is free. For verification purposes, please make sure you use your university or company e-mail.</p>
      <p>If you already have a Neuromodec Connect account, <b><Link to="/login">Sign in</Link></b> instead.</p>
          <label>
        <span>First Name*</span>
        <input
          required 
          type="text" 
          onChange={(e) => setFirstName(e.target.value)} 
          value={firstName}
        />
      </label>
      <label>
        <span>Last Name*</span>
        <input
          required 
          type="text" 
          onChange={(e) => setLastName(e.target.value)} 
          value={lastName}
        />
      </label>
      <label>
        <span>Email*</span>
        <input
          required 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
        />
      </label>
      <label>
        <span>Password*</span>
        <input
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password}
        />
      </label>
      {/* <label>
        <span>Username (Handle)</span>
        <input
          required
          type="text" 
          onChange={(e) => setDisplayName(e.target.value)} 
          value={displayName}
        />
      </label> */}
      <label>
        <span>Company/Institution*</span>
        <input
          required
          type="text" 
          onChange={(e) => setAffiliation(e.target.value)} 
          value={affiliation}
        />
      </label>
      {/* <label>
        <span>Degree*</span>
        <Select 
        required
        options={degrees}
        onChange={(option) => setDegree(option)}
        value={degree}
        />
      </label> */}
      {/* <p>By signing up you agree to our terms of service.</p> */}
      {/* <label>
        <span>Profile image (Recommended) (must be in .jpg .jpeg or .png format) (Maximum file size: 1 MB)</span>
        <input 
          type="file"
          onChange={handleFileChange}
        />
        {thumbnailError && <div className="error">{thumbnailError}</div>}
      </label> */}
      {!isPending && <button className="btn">Sign up</button>}
      {isPending && <button className="btn" disabled>loading</button>}
      {error && <div className="error">{error}</div>}
    </form>
    </div>

    <div className="rightContainer">

    </div>
    </div>

  )
}