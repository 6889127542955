import React from 'react'

import "firebase/firestore"
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'

import PostList from '../../components/PostList'


export default function UserPosts() {
    const { user } = useAuthContext()
    const {documents, error } = useCollection(
        'posts',["posted_by", "==", user.uid],
        ["createdAt", "desc"]
        )

  return (
    <div className="posts-by-this-user">
        {documents && <PostList posts = {documents} />}
    </div>
  )
}
