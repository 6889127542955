
import { Link } from 'react-router-dom'
import './Publications.css'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import Alert from '@mui/material/Alert';


export default function Publications() {

    const { user } = useAuthContext()

    const {documents, error } = useCollection('publications',["createdBy","==",user.uid],["createdAt", "desc"])
        

console.log(documents)
  return (
    <div className="publications-wrap">

        <div className="publications-header">
            <h3>Publications</h3>
            <p><Link to="/addPublication"><AddIcon/></Link></p>
        </div>

        {documents && documents.map(publication => ( 

        <div key={publication.id} className="publication-card">
            <p>{publication.details}</p>
            <Button variant="outlined" href={publication.doi_url}>
                DOI
            </Button>
        </div>
   
        ))}
       
    </div>
  )
}
