import './EventOrganizer.css'
import organizerLogo from '../assets/logo.svg'

export default function EventOrganizer({event}) {
  return (
    <div className="attendees-wrap">
    <div className="attendee-header">
    <h3>Host</h3>
    </div>
  
    <div className="attendee-body">

      {event.hosts.length > 0 && event.hosts.map(host => (
        
      <div key={host.id} className="attendee-card">
        <div className="attendee-img">
        <img src={host.photoURL} alt="Host Profile picture" />
        </div>
        <div className="attendee-info">
        <h4>{host.first_name} {host.last_name}</h4>
        <p>{host.affiliation}</p>
        </div>
      </div>
        ))}
       </div>
   
      </div>
  )
}
