import './Widgets.css'
import { useAuthContext } from '../hooks/useAuthContext'
import { useLogout } from '../hooks/useLogout'
import Avatar from "./Avatar"
import EventWidget from '../components/EventWidget'
import SponsorWidget from './SponsorWidget'



export default function Widgets() {
  const { logout, isPending } = useLogout()
  const { user } = useAuthContext()
    
  return (
    <div className="widgets">
        <div className="widgetsHeader">
        <div className="user">
          <Avatar src={user.photoURL} />
          {/* <span>@{user.displayName}</span> */}
          </div> 
          {user && (
            <div className="logoutDiv">
          <li>
          {!isPending && <button className="btn" onClick={logout}>Logout</button>}
          {isPending && <button className="btn" disabled>Logging out...</button>}
           </li>
           </div>
            )}
            
        </div>
    <SponsorWidget />
    <EventWidget />
    </div>
  )
}
