import './SponsorWidget.css'
import AbbottLogo from '../assets/abbott.png'
import SmLogo from '../assets/sm.jpeg'
import ConferenceBanner from '../assets/2024nycn_banner.webp'
import { Link } from 'react-router-dom'


export default function SponsorWidget() {
  return (
    
    <div className="sponsorWid-wrap">
        <h3>Featured Upcoming Conference</h3>
        <ul>
            <li>
            <div className="sponsorWid-item">
              <a target="_blank" href="https://bit.ly/47yDCnW">
            <img src={ConferenceBanner} alt="" className="sponsor-img" />
              </a>
            </div>  
            </li>
        </ul>
    </div>
  )
}
