import './PostLikes.js'

import { useDocument } from '../hooks/useDocument'
import { Link } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import { useState,useEffect } from "react"
import { timestamp } from '../firebase/config'
import { useAuthContext } from '../hooks/useAuthContext'
import { useFirestore } from '../hooks/useFirestore'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';



export default function PostLikes({postLike}) {


    const { updateDocument, response } = useFirestore('posts')
    const [userLikedPost, setUserLikedPost] = useState(false)
    const { user } = useAuthContext()
    

  
   useEffect(()=>{
    const postlikes = postLike.likes
   postlikes.map(postlike => {
   
    if (user.uid === postlike.liked_by) {
     setUserLikedPost(true)
   }
  
    })
})


const clicked = async (e) => {

const randomId = user.displayName
const idLeft = randomId.slice(2,4)
const idRight = randomId.slice(3,5)

const createRandomId = `${idLeft}-${Math.random()}-${idRight}`

    e.preventDefault()

        const likeToAdd = {
                
          createdAt: timestamp.fromDate(new Date()),
          id: createRandomId,
          liked_by:user.uid
        
              }
    
              
              await updateDocument(postLike.id, {   
                  likes: [...postLike.likes, likeToAdd]
                  
              })
    
    
          if (!response.error) {
     
             }
        
      }

    
  return (
      <div className="post-likes">

        {!userLikedPost && <button onClick={clicked}><FavoriteBorderIcon /></button>}
        {userLikedPost && <button><FavoriteIcon style={{ color: "red" }}/></button>}
         <p>{postLike.likes.length}</p>
        </div>
  )
}
