import './AddPublication.css'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useCollection } from '../hooks/useCollection'
import { useAuthContext } from '../hooks/useAuthContext'
import { timestamp } from '../firebase/config'
import { useFirestore } from '../hooks/useFirestore'
import { useHistory } from 'react-router'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Snackbar from '@mui/material/Snackbar';

export default function AddPublication() {

    const { user } = useAuthContext()
    const history = useHistory()
    const { addDocument, response } = useFirestore('publications')
    const [details, setDetails] = useState('')
    const [doiURL, setDoiUrl] = useState('')
    const [publishDate, setPublishDate] = useState('')


  const handleSubmit = async (e) => {
    e.preventDefault()
   

    const publication = {

      details,
      createdBy:user.uid,
      doi_url:doiURL,
      publish_date: timestamp.fromDate(new Date(publishDate))
    }

    await addDocument(publication)
    
    if (!response.error) {
        setPublishDate('')
        setDoiUrl('')
        setDetails('')
    }
  }

  return (

    <div className="addPubs">
         <div className="profileHeader">
         <Link to={`/profile/${user.uid}`}><ArrowBackOutlinedIcon fontSize="medium"/></Link>
        </div>

        <h2>Add a new publication</h2>

        <div className="create-form">
        <form onSubmit={handleSubmit}>
        
        <label>
          <span> Publication (MLA format)</span>
          <textarea 
            required
            onChange={(e) => setDetails(e.target.value)}
            value={details}
          ></textarea>
        </label>
        <label>
          <span>Publication Date</span>
          <input
            required 
            type="date" 
            onChange={(e) => setPublishDate(e.target.value)} 
            value={publishDate}
          />
        </label>
        <label>
          <span>DOI URL (example: https://doi.org/10.1016/j.brs.2023.03.007) </span>
          <input
            required 
            type="text" 
            onChange={(e) => setDoiUrl(e.target.value)}
            value={doiURL}
          />
        </label>

        <button className="btn">Add Publication</button>
     
      </form>
        </div>
    </div>
    
  )
}
