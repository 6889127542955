import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_UOnh_wzdNj-WGkArM6LKLtY9idhpFwk",
  authDomain: "neuromodec-community.firebaseapp.com",
  projectId: "neuromodec-community",
  storageBucket: "neuromodec-community.appspot.com",
  messagingSenderId: "36309834237",
  appId: "1:36309834237:web:b0b8bd3142594ea5da2194",
  measurementId: "G-3J8SXBWZH4"
};
  // init firebase

  firebase.initializeApp(firebaseConfig)

  // init services

  const projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()
  const projectAnalytics = firebase.analytics();

  // timestamp
  const timestamp = firebase.firestore.Timestamp
 const timestampDate = firebase.firestore.Timestamp.now().toDate().toString()
  
  export { projectFirestore, projectAuth, projectStorage, projectAnalytics, timestamp, timestampDate}
