import './EventAttendees.css'

import { useState } from "react"
import { timestamp } from '../firebase/config'
import { useAuthContext } from '../hooks/useAuthContext'
import "firebase/firestore";
import Alert from '@mui/material/Alert';



export default function EventAttendees( {event} ) {

  const { user } = useAuthContext()

  

  return (

   <div className="attendees-wrap">
    <div className="attendee-header">
    <h3>Attending ({event.attendees.length})</h3>
    </div>
    {!user && <Alert severity="info">Sign in to see who's attending this event</Alert>}
 {user && 
    <div className="attendee-body">

    {event.attendees.length > 0 && event.attendees.map(attendee => (
 
      <div key={attendee.id} className="attendee-card">
        <div className="attendee-img">
        {/* <img src={attendee.photoURL} alt="Attende Profile picture" /> */}
        </div>
        <div className="attendee-info">
        <h4>{attendee.first_name} {attendee.last_name}</h4>
        <p>{attendee.affiliation}</p>
        </div>
      </div>
        ))}

    </div>
   }
   </div>
  )
}
