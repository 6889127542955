import "./Profile.css"
import { Box, Tab, Tabs} from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab'
import React, { useState } from "react";
import EventSponsor from '../../components/EventSponsors'
import About from "./About";
import UserPosts from './UserPosts'
import firebase from "firebase/app";
import { useAuthContext } from '../../hooks/useAuthContext'
import "firebase/firestore";
import Publications from './Publications'

export default function Profile() {

  const { user } = useAuthContext()

  const [val, setVal] = useState('one');
  
    const handleTab = (e, newVal) => {
        setVal(newVal);
    };

const [userFirstName, setUserFirstName] = useState('')
const [userLastName, setUserLastName] = useState('')
const [userAffiliation, setUserAffiliation] = useState('')
const [userDegree, setUserDegree] = useState('')

const db = firebase.firestore();


var docRef = db.collection("users").doc(user.uid);

docRef.get().then((doc) => {
   
 if (doc.exists) {
     var cUser = doc.data();
     setUserFirstName(cUser.first_name)
     setUserLastName(cUser.last_name)
     setUserAffiliation(cUser.affiliation)

 } else {
     // doc.data() will be undefined in this case
     console.log("No such document!");
 }
}).catch((error) => {
 console.log("Error getting document:", error);
});

  return (
   
    <div className="userProfile">
      <div className="profileHeader">
      <h2>Profile</h2>
      </div>

      <div className="profileDetailsWrap">
        <div className="profile-img-wrap">
        <img src={user.photoURL} alt="" className="profile-img" />
        </div>
      
        <div className="profile-details">
        <h3>{userFirstName} {userLastName}</h3>
        {/* <p>@{user.displayName}</p> */}
        <p>{userAffiliation}</p>
        </div>
        {/* <div className="edit-profile">
          <button className="btn">Edit Profile</button>
        </div> */}
      
      </div>

    <center>
                <Box>
                    <TabContext
                        value={val}
                        onChange={handleTab}
                        textColor="primary"
                        indicatorColor="secondary"
                    >
                      <Box>
                      <TabList onChange={handleTab} centered>
                      <Tab value="one" label="Posts">
                        
                        </Tab>
                        <Tab value="two" label="Publications">
                         
                        </Tab>
                      
                      </TabList>
                      </Box>
                      <TabPanel value='one'><UserPosts /></TabPanel>
                      <TabPanel value='two'><Publications /></TabPanel>
                    </TabContext>
                </Box>
               
       </center>

      
      </div>


  )
}
