import './EventWidget.css'
import { useCollection } from '../hooks/useCollection'
import { useEffect, useState } from 'react'
import "firebase/firestore";
import { Link } from 'react-router-dom'

export default function EventWidget( ) {
        
  const { documents, error } = useCollection('events')
 

 
  return (
  

    <div className="eventWid-wrap">
        <h3>Upcoming Events</h3>
        <ul>
          {error && <p className="error">{error}</p>}
          {documents && documents.map(doc => (
         
             <li key={doc.id}>
              <Link to={`/events/${doc.id}`}>  
             <div className="eventWid-item">
                 <span>{doc.type}</span>
                  {/* {doc.starting_soon && <p><span class="soon-badge-small">STARTS SOON</span></p>} */}
                     {/* {doc.zoom_url && <p><span class="live-badge-small">Live</span></p>} */}
                 <h4>{doc.name}</h4>
                 <p>{doc.date.toDate().toDateString()}</p>
                 
             </div>
             </Link>
             </li>
            
          ))}

        </ul>
    </div>
  )
}
