import { useState, useEffect } from 'react'
import { projectAuth, projectStorage, projectFirestore, timestamp } from '../firebase/config'
import { useAuthContext } from './useAuthContext'


export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (firstName, lastName, email, password, displayName, affiliation) => {
    setError(null)
    setIsPending(true)
  

    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

     
      // upload user thumbnail
      // const uploadPath = `thumbnails/${res.user.uid}/profile-img`
      // const img = await projectStorage.ref(uploadPath).put(thumbnail)
      const imgUrl = 'https://firebasestorage.googleapis.com/v0/b/neuromodec-community.appspot.com/o/defaultThumbnail%2Ftemplate.jpg?alt=media&token=d067501d-5c67-42c0-a738-e3a05bc3883e&_gl=1*146fnnd*_ga*MjA0MzYwNTU4MS4xNjk3NjU0NjE4*_ga_CW55HF8NVT*MTY5ODg1NTc1NC40NS4xLjE2OTg4NTcyNDUuNjAuMC4w'

      // add display AND PHOTO_URL name to user
      await res.user.updateProfile({displayName, photoURL: imgUrl})

      // create a user document

      await projectFirestore.collection('users').doc(res.user.uid).set({ 
        first_name: firstName,
        last_name: lastName,
        email,
        handle: displayName,
        affiliation,
        photoURL: imgUrl,
        
      })

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}